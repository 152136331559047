import { createStore } from 'vuex'
import axios from 'axios'
import config from '../config'

const axiosWithAuth = function (state) {
  return axios.create({
    baseURL: config.API_ROOT,
    headers: { Authorization: `Token ${state.authToken}` }
  })
}

const getCurrentPosition = (options) => {
  if (navigator.geolocation) {
    return new Promise(
      (resolve, reject) => {
        const timeout = options.timeout || 5000
        let rejected = false
        const pt = setTimeout(() => { rejected = true; reject(new Error()) }, timeout)
        navigator.geolocation.getCurrentPosition(
          (pos) => { clearTimeout(pt); if (!rejected) resolve(pos) },
          (err) => { clearTimeout(pt); if (!rejected) reject(err) },
          options)
      }
    )
  }
  return Promise.reject(new Error())
}

const unpackErrorMessage = (err) => {
  if (err.response && err.response.data && err.response.data.error) {
    throw err.response.data.error
  }
  if (err.response && err.response.data && err.response.data.detail) {
    throw err.response.data.detail
  }
  if (err.response && err.response.data && err.response.data.message) {
    throw err.response.data.message
  }
  throw err
}

const LS_AUTH_TOKEN_KEY = 'authToken'

const store = createStore({
  state: {
    packageVersion: config.APP_VERSION || '0',
    authToken: null,
    user: config.TEST_USER !== '' ? { username: config.TEST_USER } : undefined,
    rents: [],
    rent_list: [],
    bike_info: null,
    appError: '',
    gbfs: null,
    lock: {}
  },
  actions: {
    AUTHENTICATE: function ({ commit, dispatch }, authToken) {
      commit('SET_AUTH_TOKEN', authToken)
      return dispatch('GET_USER')
    },
    IS_AUTHENTICATED: function ({ dispatch, getters }) {
      if (!getters.isAuthenticated) {
        return dispatch('LOAD_AUTH_TOKEN')
          .then(() => dispatch('GET_USER'))
      }
      return Promise.resolve()
    },
    GET_USER: function ({ commit, state, getters }) {
      if (!getters.isAuthenticated) { return Promise.reject(new Error()) }
      return axiosWithAuth.call(this, state)
        .get('/user')
        .then(
          response => {
            commit('SET_USER', { user: response.data })
          },
          err => {
            if (err.response && err.response.status === 401) {
              commit('CLEAR_USER')
              return Promise.reject(new Error())
            }
          }
        )
    },
    LOAD_AUTH_TOKEN: function ({ commit }) {
      return new Promise((resolve, reject) => {
        const authToken = localStorage.getItem(LS_AUTH_TOKEN_KEY)
        if (authToken !== null) {
          commit('SET_AUTH_TOKEN', authToken)
          resolve()
          return
        }

        reject(new Error())
      })
    },
    LOGOUT: function ({ commit }) {
      commit('CLEAR_USER')
    },
    START_RENT: async function ({ dispatch, state }, bikeNumber) {
      let location
      try {
        location = await getCurrentPosition({ timeout: 3000, enableHighAccuracy: true, maximumAge: 20000 })
      } catch (_ignore) { /* */ }

      const data = { bike: bikeNumber }
      if (location && location.coords && location.coords.accuracy < 20) {
        data.lat = location.coords.latitude
        data.lng = location.coords.longitude
      }

      try {
        const response = await axiosWithAuth.call(this, state).post('/rent', data)
        dispatch('UPDATE_RENTS')
        return response.data
      } catch (err) {
        throw unpackErrorMessage(err)
      }
    },
    END_RENT: async function ({ dispatch, commit, state }, rentId) {
      let location
      try {
        location = await getCurrentPosition({ timeout: 3000, enableHighAccuracy: true, maximumAge: 20000 })
      } catch (_ignore) { /* */ }

      const data = {}
      if (location && location.coords && location.coords.accuracy < 50) {
        data.lat = location.coords.latitude
        data.lng = location.coords.longitude
      }

      try {
        const finishUrl = state.rents.find((el) => el.id === rentId).finish_url
        const response = await axiosWithAuth.call(this, state).post(finishUrl, data)
        dispatch('UPDATE_RENTS')
        commit('REMOVE_LOCK', rentId)
        return response.data
      } catch (err) {
        throw unpackErrorMessage(err)
      }
    },
    UPDATE_RENTS: function ({ commit, state, getters }) {
      if (!getters.isAuthenticated) { return }
      axiosWithAuth.call(this, state)
        .get('/rent')
        .then(response => {
          commit('SET_RENTS', response.data)
        })
    },
    GET_ALL_RENTS: function ({ commit, state, getters }) {
      if (!getters.isAuthenticated) { return }
      axiosWithAuth.call(this, state)
        .get('/rent/{}/get_rents')
        .then(response => {
          commit('SET_RENT_LIST', response.data)
        })
    },
    GET_BIKE_INFO: function ({ commit, state, getters }, bikeId) {
      if (!getters.isAuthenticated) { return }
      axiosWithAuth.call(this, state)
        .get('/bikeinfo/' + bikeId + '/get_bike_info')
        .then(response => {
          commit('SET_BIKE_INFO', JSON.parse(response.data))
        })
    },
    RENT_UNLOCK: async function ({ commit, state }, rentId) {
      try {
        const unlockUrl = state.rents.find((el) => el.id === rentId).unlock_url
        const response = await axiosWithAuth.call(this, state).post(unlockUrl)
        if (response.data.success) {
          commit('SET_LOCK', { rentId, data: response.data.data })
          return response.data.data
        } else {
          throw new Error(response)
        }
      } catch (err) {
        throw unpackErrorMessage({ response: err.message })
      }
    },
    ACCEPT_TOS: async function ({ state }, { user, version }) {
      try {
        const response = await axios({
          method: 'post',
          url: `${config.API_ROOT}/user/${user.pk}/has_read_tos`,
          headers: {
            Authorization: `Token ${state.authToken}`,
            'Content-Type': 'application/json'
          },
          data: { version }
        })

        if (response.status === 200) {
          return response.data
        } else {
          throw new Error(response)
        }
      } catch (err) {
        throw unpackErrorMessage({ response: err.message })
      }
    },
    GET_LATEST_TOS: async function ({ state }) {
      try {
        const response = await axiosWithAuth.call(this, state)
          .get('/tos/get_latest_tos')

        if (response.status === 200) {
          return response.data
        } else {
          throw new Error(response)
        }
      } catch (err) {
        throw unpackErrorMessage({ response: err.message })
      }
    }
  },
  mutations: {
    CLEAR_USER: (state) => {
      state.authToken = null
      state.user = undefined
      state.rents = []
      state.lock = []
      state.bike_info = null
      localStorage.removeItem(LS_AUTH_TOKEN_KEY)
    },
    CLEAR_BIKE_INFO: (state) => {
      state.bike_info = null
    },
    SET_USER: (state, { user }) => {
      state.user = user
    },
    SET_AUTH_TOKEN: (state, authToken) => {
      state.authToken = authToken
      localStorage.setItem(LS_AUTH_TOKEN_KEY, authToken)
    },
    SET_RENTS: (state, rents) => {
      state.rents = rents
    },
    SET_RENT_LIST: (state, rent_list) => {
      state.rent_list = rent_list
    },
    SET_BIKE_INFO: (state, bike_info) => {
      state.bike_info = bike_info
    },
    SET_LOCK: (state, { rentId, data }) => {
      state.lock[rentId] = data
    },
    REMOVE_LOCK: (state, rentId) => {
      if (Object.prototype.hasOwnProperty.call(state.lock, rentId)) {
        delete state.lock[rentId]
      }
    },
    SET_APPERROR: (state, message) => {
      state.appError = message
    },
    SET_GBFS: (state, data) => {
      state.gbfs = data
    }
  },
  getters: {
    appVersion (state) {
      return state.packageVersion
    },
    isAuthenticated (state) {
      return !!state.authToken
    },
    getGBFSBikeWithDetails: (state) => (id) => {
      const bike = state.gbfs?.freeBikeStatus.data.bikes.find((b) => b.bike_id === id)
      if (typeof bike === 'undefined') return null
      if (typeof bike.vehicle_type_id !== 'undefined' && typeof state.gbfs.vehicleTypes !== 'undefined') {
        const vehicleType = state.gbfs.vehicleTypes.data.vehicle_types.find((vt) => vt.vehicle_type_id === bike.vehicle_type_id)
        return { bike, vehicle_type: vehicleType }
      }
      return { bike }
    },
    getGBFSStationWithDetails: (state) => (id) => {
      const station = state.gbfs.stations.data.stations.find((s) => s.station_id === id)
      if (typeof station === 'undefined') return null
      const stationStatus = state.gbfs.stationStatus.data.stations.find((s) => s.station_id === station.station_id)
      if (typeof stationStatus !== 'undefined') {
        if (typeof stationStatus.vehicle_docks_available !== 'undefined' ||
          typeof stationStatus.vehicles.find((v) => typeof v.vehicle_type_id !== 'undefined') !== 'undefined') {
          const vehicleTypes = state.gbfs.vehicleTypes.data.vehicle_types
          return { station, station_status: stationStatus, vehicle_types: vehicleTypes }
        }
        return { station, station_status: stationStatus }
      }
      return { station }
    }
  },
  modules: {}
})

export default store
