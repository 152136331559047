<template>
  <div class="d-flex align-center">
    <v-dialog v-model="dialog" class="d-flex">

      <!-- BUTTON with activator for dialog -->
      <template v-slot:activator="{ on }">
        <v-btn v-bind=on variant="text" color="lighterred" @click="handleClick()" class="about-toolbar-btn d-sm-flex">
          <span class="d-none d-sm-flex">{{ $t('message.about.title') }}</span>
          <v-icon end>{{ mdi.informationOutline }}</v-icon>
        </v-btn>
      </template>

      <!-- CARD -->
      <v-card theme="themelight" max-height="70vh">
        <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
          {{ $t('message.about.title') }}
        </v-card-title>

        <v-card-text>
          <p class="mt-4 mb-0" v-html="$t('message.about.html')"></p>
        </v-card-text>

        <!-- FAQ PANELS with I18N -->
        <v-row justify="center" width="inherit" style="margin:inherit; overflow-y: scroll;">
          <v-expansion-panels variant="accordion">
            <v-expansion-panel>
              <v-expansion-panel-title><b>FAQ</b></v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-expansion-panels>

                  <template v-for="message in $tm('message.faq')" :key="message.title">
                    <v-expansion-panel>
                      <v-expansion-panel-title>{{ message.title }}</v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <p class="mt-4 mb-0" v-html="message.html"></p>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </template>

                </v-expansion-panels>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>

        <v-divider></v-divider>
        <v-card-actions sticky>
            <v-btn variant="text" @click="dialog = false">{{ $t('message.apperror.close') }}</v-btn>
            <v-spacer></v-spacer>
            <p align="right" style="margin-right:3px" class="text-grey"><small>v{{ version }}</small></p>
          </v-card-actions>

      </v-card>
    </v-dialog>

  </div>
</template>

<script setup>
import { computed, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { mdiInformationOutline } from '@mdi/js'

const store = useStore()
const router = useRouter()

const dialog = ref(false)
const mdi = reactive({ informationOutline: mdiInformationOutline })

/* Get version from Vuex Store */
const version = computed(() => store.getters.appVersion)

const handleClick = () => {
  router.push('/') // always return to base url
  dialog.value = true
}
</script>

<style>
.about-toolbar-btn {
  border-radius: 0;
  height: 100% !important;
  max-height: none;
}
</style>
