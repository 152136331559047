<template>
  <div>
    <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>{{ $t('message.detail.info') }}</v-card-title>

    <v-divider></v-divider>
    <v-card-text :style="'padding: 0; max-height: 60vh; max-width:' + width + '; overflow-y: scroll;'">

      <img v-if="bikeinfo['image']" :src="bikeinfo['image']" :style="'max-width: ' + width" />

      <div id="bikeinfo" v-html="bikeinfo['info']"></div>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useDisplay } from 'vuetify'

const display = useDisplay()

const width = computed(() => {
  if (display.xs.value) {
    return '90vw'
  } else if (display.sm.value) {
    return '70vw'
  } else {
    return '40vw'
  }
})

defineProps({
  bikeinfo: Object
}
)
</script>

<style>
tr:nth-child(even) {
  background-color: lightgray;
}

td {
  padding: 5pt;
}

table {
  border-spacing: 0 2pt;
  width: 100%;
}
</style>
