<template>
  <div id="attach-sheet">
    <v-bottom-sheet v-model="show" inset eager hide-overlay attach="#attach-sheet" transition="none">
      <v-sheet height="180px">

        <v-card-title v-if="!hasData" class="pb-3">
          <v-skeleton-loader type="list-item" width="100%"></v-skeleton-loader>
        </v-card-title>

        <v-card-title v-if="hasData" class="pb-3">
          <span style="font-weight: normal;">{{ name }}</span>
        </v-card-title>

        <v-card-text>
          <!-- VEHICLE IN STATION LIST -->
          <ul class="vehicle-list">

            <li v-for="item in vehicleTypeCount" :key="item.id" :title="item.name">
              <v-badge v-if="item.electric" dot color="yellow" class="me-1">
                <v-avatar tile size="24">
                  <v-icon size="16">{{ item.icon }}</v-icon>
                </v-avatar>
              </v-badge>
              <v-icon size="16" class="me-1" v-if="!item.electric">{{ item.icon }}</v-icon>
              {{ item.count }} {{ item.maximum ? '/ ' + item.maximum : '' }}
            </li>

            <li v-if="parking">
              <v-avatar color="blue" size="24" tile class="me-1">
                <v-icon color="white" size="16">{{ iconParking }}</v-icon>
              </v-avatar> {{ parking }}
            </li>

          </ul>
        </v-card-text>
      </v-sheet>
    </v-bottom-sheet>
  </div><!-- #attach-sheet -->
</template>

<script setup>
import { computed, defineProps, ref, watch, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import {
  mdiBicycle,
  mdiBicycleBasket,
  mdiScooter,
  mdiCar,
  mdiMoped,
  mdiBus,
  mdiParking
} from '@mdi/js'

const show = ref(true)
const routeUpdateHappened = ref(false)
const hasData = ref(false)
const station = ref(undefined)
const station_status = ref(undefined)
const vehicle_types = ref(undefined)
const iconParking = ref(mdiParking)

const store = useStore()
const router = useRouter()
const route = useRoute()

const props = defineProps(['id'])

/* Get station name */
const name = computed(() => {
  if (typeof station.value === 'undefined') return ''
  return station.value.name
})

/* Get number of free docks of station */
const parking = computed(() => {
  if (typeof station.value === 'undefined') return ''
  if (typeof station_status.value !== 'undefined' && typeof station_status.value.num_docks_available !== 'undefined') {
    return station_status.value.num_docks_available
  }
  return station.value.capacity
})

/* Get number of vehicles in station */
const vehicleTypeCount = computed(() => {
  if (typeof station_status.value === 'undefined') return []

  const typeCount = {}
  for (const v of station_status.value.vehicles) {
    if (typeof typeCount[v.vehicle_type_id] === 'undefined') {
      typeCount[v.vehicle_type_id] = { id: v.vehicle_type_id, count: 0 }
      const type = vehicle_types.value.find((vt) => vt.vehicle_type_id === v.vehicle_type_id)
      if (typeof type !== 'undefined') {
        typeCount[v.vehicle_type_id].name = type.name
        typeCount[v.vehicle_type_id].icon = iconForVehicleType(type)
        typeCount[v.vehicle_type_id].electric = vehicleTypeElectric(type)
      }
    }
    typeCount[v.vehicle_type_id].count += 1
  }
  if (typeof station_status.value.vehicle_docks_available !== 'undefined') {
    for (const o of station_status.value.vehicle_docks_available) {
      for (const type of o.vehicle_type_ids) {
        if (typeCount[type] !== 'undefined') {
          typeCount[type].maximum = o.count
        }
      }
    }
  }

  return Object.values(typeCount)
})

/* Get GBFS data from Vuex Store */
const gbfs = computed(() => store.state.gbfs)

/* Get Station Details from GBFS */
const loadDetailFromGbfs = () => {
  hasData.value = false

  const stn = store.getters.getGBFSStationWithDetails(props.id)
  if (stn !== null) {
    hasData.value = true
    station.value = stn.station
    station_status.value = stn.station_status
    vehicle_types.value = stn.vehicle_types
  } else {
    hasData.value = false
  }
}

/* Get vehicle icons */
const iconForVehicleType = (type) => {
  if (typeof type === 'undefined') return mdiBicycle
  if (type.form_factor === 'bike') {
    if (type.name.toLowerCase().includes('cargo')) return mdiBicycleBasket
    else return mdiBicycle
  }
  if (type.form_factor === 'scooter') return mdiScooter
  if (type.form_factor === 'car') return mdiCar
  if (type.form_factor === 'moped') return mdiMoped
  if (type.form_factor === 'other') return mdiBus
  return mdiBicycle
}

/* Get bool whether vehicle is electric */
const vehicleTypeElectric = (type) => {
  if (type.propulsion_type === 'electric' ||
    type.propulsion_type === 'electric_assist') {
    return true
  }
  return false
}

/* WATCHER for dialog behavior */
watch(show, (current) => {
  if (current === false && routeUpdateHappened.value) {
    // from detail view of one station to another
    routeUpdateHappened.value = false
    nextTick(() => {
      show.value = true
    })
  } else if (current === false && !routeUpdateHappened.value) {
    // closing because of click on background
    router.push('/')
  }
})

/* WATCHER for route updates */
watch(route, (to) => {
  // displaying another station
  if (to.name === 'station') {
    routeUpdateHappened.value = true
    loadDetailFromGbfs()
  }
})

/* WATCHER for GBFS changes */
watch(gbfs, () => {
  loadDetailFromGbfs()
})
</script>

<style>
.v-avatar .v-icon {
  border-radius: 0;
}

ul.vehicle-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.vehicle-list li {
  margin-right: 1em;
}
</style>
