import { createApp } from 'vue'

import store from './store'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import './registerServiceWorker'

import config from './config'

import i18n from './i18n'
import { makeRouter } from './router'
import 'vuetify/styles'

const app = createApp(App)

app.provide('$appConfig', config)

document.title = config.TITLE

app.component('no-op', {
  functional: true,
  render () { return null }
})

const noop = app.component('no-op')

const router = makeRouter(noop)

app.use(vuetify)
app.use(store)
app.use(router)
app.use(i18n)

app.mount('#app')

if (config.SENTRY_DSN) {
  import(/* webpackChunkName: "sentry" */ './sentry')
}
