<template>
    <v-dialog persistent max-width="80%">

        <v-card>
            <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
                {{ $t('message.tos.title') }}
            </v-card-title>

            <v-card-text>{{ $t('message.tos.info') }}</v-card-text>

            <v-divider></v-divider>

            <VuePdfEmbed :source="props.url" />

            <v-divider></v-divider>

            <v-card-actions>
                <v-btn :icon="mdiDownload" :href="props.url" download></v-btn>

                <v-spacer></v-spacer>

                <v-btn @click="store.dispatch('ACCEPT_TOS', { user: props.user, version: props.version }).then(() => store.dispatch('GET_USER'))">{{
                    $t('message.tos.accept') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import VuePdfEmbed from 'vue-pdf-embed'
import { defineProps } from 'vue'
import { useStore } from 'vuex'
import { mdiDownload } from '@mdi/js'

const store = useStore()

const props = defineProps({
  user: Object,
  url: String,
  version: String
})
</script>
