<template>
  <v-snackbar v-model="show" location="bottom left">
    {{ message }}
    <v-btn variant="text" @click="show = false">
      {{ $t('message.apperror.close') }}
    </v-btn>
  </v-snackbar>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'

const show = ref(false)
const message = ref('')

const store = useStore()

/* WATCHER for errors that may come in via Vuex Store */
store.watch(state => state.appError, () => {
  const msg = store.state.appError
  if (msg !== '') {
    show.value = true
    message.value = store.state.appError
    store.commit('SET_APPERROR', '')
  }
})
</script>

<style>
.v-alert {
  width: 100%;
  position: absolute;
  z-index: 2400;
}
</style>
