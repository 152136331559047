const pjson = require('../package.json')
const ENV = 'staging'

const TITLE = 'dev.bike - experimental bikesharing with an open source stack'
const NAME = 'dev.bike'
const SYSTEM_URL = 'https://api.dev.bike'
const API_ROOT = SYSTEM_URL + '/api'
const GBFS_URL = SYSTEM_URL + '/gbfs/gbfs.json'

const DEFAULT_LOCATION = [9.9908, 48.3984]
const DEFAULT_ZOOM = 14
const APP_VERSION = pjson.version

const I18N_MESSAGE_OVERRIDE = {}

const SUPPORT_TYPE = 'zammad'
const SUPPORT_URL = 'https://support.ulm.dev'

const SENTRY_DSN = ''

module.exports = { ENV, TITLE, NAME, API_ROOT, APP_VERSION, GBFS_URL, DEFAULT_LOCATION, DEFAULT_ZOOM, I18N_MESSAGE_OVERRIDE, SUPPORT_TYPE, SUPPORT_URL, SENTRY_DSN }
