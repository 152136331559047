import { createI18n } from 'vue-i18n'
import { mergeDeep } from './util'

import config from '../config'

const messages = require('./messages.json')

if (config.I18N_MESSAGE_OVERRIDE) {
  mergeDeep(messages, config.I18N_MESSAGE_OVERRIDE)
}

const i18n = createI18n({
  locale: 'de', // TODO: get browser locale
  messages,
  fallbackLocale: 'en',
  legacy: false
})

export default i18n
