const pjson = require('../package.json')
const ENV = 'development'

const TITLE = 'esel.ac - Free, communal bikesharing in Aachen.'
const NAME = 'esel.ac'
const SYSTEM_URL = 'https://di.esel.ac'
const API_ROOT = SYSTEM_URL + '/api'
const GBFS_URL = SYSTEM_URL + '/gbfs/gbfs.json'

const DEFAULT_LOCATION = [6.0839, 50.7753]
const DEFAULT_ZOOM = 14
const APP_VERSION = pjson.version

const I18N_MESSAGE_OVERRIDE = {
  de: {
    message: {
      about: {
        html: 'Kostenloses Bikesharing in Aachen<br/>Bitte habe Verständnis, dass esel.ac nicht sofort für alle verfügbar ist. Um ein Konto zu erstellen, lassen dich von jemandem einladen, der bereits eines hat, oder <a href="mailto:hello{\'@\'}esel.ac">kontaktiere uns!</a><br/>Vielleicht hast du ein altes Fahrrad, das du mit der Community teilen möchtest? :) Wir würden uns sehr freuen, wenn du uns kontaktieren würdest!'
      }
    }
  },
  en: {
    message: {
      about: {
        html: 'Free bikesharing in Aachen<br/>Please be considerate of the fact esel.ac is not immediately available to everyone. To create an account, get invited by someone that already has one or <a href="mailto:hello{\'@\'}esel.ac">contact us!</a><br/>Maybe you have an old bike you want to share with the community? :) We would very happy if you would contact us!'
      }
    }
  }
}

const SUPPORT_TYPE = ''
const SUPPORT_URL = ''

const SENTRY_DSN = ''
const TEST_USER = ''
// TEST_USER = 'Linus.WhatThe.Heck'

module.exports = { ENV, TITLE, NAME, API_ROOT, APP_VERSION, GBFS_URL, DEFAULT_LOCATION, DEFAULT_ZOOM, I18N_MESSAGE_OVERRIDE, SUPPORT_TYPE, SUPPORT_URL, SYSTEM_URL, SENTRY_DSN, TEST_USER }
