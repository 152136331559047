<template>
  <v-btn rounded size="x-large" color="success" :to="{ name: 'rent' }">
    <p style="color:white">
      <v-icon>{{ mdi.bike }}</v-icon><span class="pl-2">{{ buttonText }}</span>
    </p>
  </v-btn>
</template>

<script setup>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { mdiBike } from '@mdi/js'

const { t } = useI18n()

const mdi = reactive({
  bike: mdiBike
})

const store = useStore()

/* Get active rents from Vuex Store */
const rents = computed(() => store.state.rents)

/* Set Button Text depending on whether there are active rents */
const buttonText = computed(() => rents.value.length === 0 ? t('message.rentbutton.rent-bike') : t('message.rentbutton.rent-or-return-bike'))
</script>
