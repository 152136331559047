<template>
  <div class="d-flex align-center">
    <v-dialog v-model="hdialog" class="d-flex" width="min-content" max-width="80%">
      <!-- CARD -->
      <v-card theme="themelight" scrollable min-width="30vw">
        <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
          {{ $t('message.history.title') }}
        </v-card-title>

        <v-card-text style="padding-left: 0; padding-right: 0; height: 23rem; overflow-y: scroll; overflow-x: clip;">
          <div id="rents">

            <v-skeleton-loader v-if="!hasData" type="table" width="500px"></v-skeleton-loader>

            <v-card-text v-if="hasData && rents.length == 0" style="text-align: center;">{{ $t('message.history.no_rents') }}</v-card-text>

            <div class="history-grid" v-if="hasData && rents.length != 0">
              <div></div>
              <div class="history-grid-header">{{ $t('message.history.rent') }}</div>
              <div class="history-grid-header">{{ $t('message.history.start') }}</div>
              <div class="history-grid-header">{{ $t('message.history.end') }}</div>
              <div class="history-grid-header">{{ $t('message.history.duration') }}</div>

              <template v-for="rent in rents" :key="rent.id">
                <div class="history-grid-row">
                  <div class="history-grid-row-hover" @click="clickTableRow(rent)"></div>
                  <div class="history-grid-row-bg"></div>
                </div>
                <div class="history-grid-value">
                  {{ Math.abs(rents.indexOf(rent) - rents.length) }}
                </div>
                <div class="history-grid-value">{{ new
                  Date(rent.rent_start).toLocaleString(["de-DE"]).replace(/(.*)\D\d+/, '$1') }}
                </div>
                <div class="history-grid-value">{{ rent.rent_end === null ? "-"
                  : new
                    Date(rent.rent_end).toLocaleString(["de-DE"]).replace(/(.*)\D\d+/, '$1') }}
                </div>
                <div class="history-grid-value">{{ rent.rent_end === null ? "-"
                  : getDiff(rent.rent_start,
                    rent.rent_end) }}
                </div>
              </template>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn variant="text" @click="hdialog = false">{{ $t('message.apperror.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>

  <div class="d-flex align-center">
    <v-dialog v-model="mdialog" class="d-flex" width="80%" height="80%">
      <v-card theme="themelight" height="100%" width="100%">
        <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
          {{ $t('message.history.rentMap.title', { id: selectedRentID }) }}
        </v-card-title>

        <v-skeleton-loader type="image" v-if="!hasLocData" width="100%" height="100%"></v-skeleton-loader>

        <div id="rentMapContainer" class="rentMap" v-if="hasLocData" ref="rentMap"></div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn variant="text" @click="mdialog = false">{{ $t('message.apperror.close') }}</v-btn>

          <v-spacer></v-spacer>

          <v-btn variant="outlined" @click="infoDialog = true;" theme="themedark" color="lighterred"><p><v-icon>{{
            mdi.informationOutline }}</v-icon></p></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

  <div class="d-flex align-center">
    <v-dialog v-model="infoDialog" class="d-flex" height="min-content" max-width="80%" max-height="90%">
      <v-card theme="themelight" height="100%" width="100%">

        <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
          {{ $t('message.history.rentMap.infoTitle') }}
        </v-card-title>

        <v-card-text>
          <p class="mt-4 mb-0" v-html="$t('message.history.rentMap.infohtml')"></p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn variant="text" @click="infoDialog = false">{{ $t('message.apperror.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, reactive, ref, onMounted, watch, watchEffect, inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import maplibregl from 'maplibre-gl'
import axios from 'axios'
import { mdiInformationOutline } from '@mdi/js'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const mdi = reactive({ informationOutline: mdiInformationOutline })

const rentMap = ref(null)

const hdialog = ref(true)
const mdialog = ref(false)
const infoDialog = ref(false)

const selectedRentID = ref(undefined)
const locations = ref([])
const hasData = ref(false)
const hasLocData = ref(false)
const routeUpdateHappened = ref(false)

const user = computed(() => store.state.user)

const rents = computed(() => store.state.rent_list)

const appConfig = inject('$appConfig')

onMounted(async () => {
  if (user.value) {
    store.dispatch('GET_ALL_RENTS')
  }
})

const clickTableRow = async (rent) => {
  selectedRentID.value = Math.abs(rents.value.indexOf(rent) - rents.value.length)
  mdialog.value = true
  await getLocations(rent)

  if (locations.value.length === 0) {
    mdialog.value = false
    store.commit('SET_APPERROR', t('message.history.rentMap.rentEmptyError'))
  }
}

const getDiff = (start, end) => {
  const diff = Math.abs(Date.parse(start) - Date.parse(end))
  let str = ''

  const remaining_time = {
    days: Math.floor(diff / (1000 * 60 * 60 * 24)).toString(),
    hours: Math.floor((diff / (1000 * 60 * 60)) % 24).toString(),
    minutes: Math.floor((diff / (1000 * 60)) % 60).toString(),
    seconds: Math.floor((diff / 1000) % 60).toString()
  }

  if (remaining_time.days < 1) {
    if (remaining_time.hours < 1) {
      if (remaining_time.minutes < 1) {
        str += remaining_time.seconds + 's'
      } else {
        if (remaining_time.seconds < 1) {
          str += remaining_time.minutes + 'min'
        } else {
          str += remaining_time.minutes + 'min ' + remaining_time.seconds + 's'
        }
      }
    } else {
      if (remaining_time.minutes < 1) {
        if (remaining_time.seconds < 1) {
          str += remaining_time.hours + 'h'
        } else {
          str += remaining_time.hours + 'h ' + remaining_time.seconds + 's'
        }
      } else {
        if (remaining_time.seconds < 1) {
          str += remaining_time.hours + 'h ' + remaining_time.minutes + 'min'
        } else {
          str += remaining_time.hours + 'h ' + remaining_time.minutes + 'min ' + remaining_time.seconds + 's'
        }
      }
    }
  } else {
    if (remaining_time.hours < 1) {
      if (remaining_time.minutes < 1) {
        if (remaining_time.seconds < 1) {
          str += remaining_time.days + 'd'
        } else {
          str += remaining_time.days + 'd ' + remaining_time.seconds + 's'
        }
      } else {
        if (remaining_time.seconds < 1) {
          str += remaining_time.days + 'd ' + remaining_time.minutes + 'min'
        } else {
          str += remaining_time.days + 'd ' + remaining_time.minutes + 'min ' + remaining_time.seconds + 's'
        }
      }
    } else {
      if (remaining_time.minutes < 1) {
        if (remaining_time.seconds < 1) {
          str += remaining_time.days + 'd ' + remaining_time.hours + 'h'
        } else {
          str += remaining_time.days + 'd ' + remaining_time.hours + 'h ' + remaining_time.seconds + 's'
        }
      } else {
        if (remaining_time.seconds < 1) {
          str += remaining_time.days + 'd ' + remaining_time.hours + 'h ' + remaining_time.minutes + 'min'
        } else {
          str += remaining_time.days + 'd ' + remaining_time.hours + 'h ' + remaining_time.minutes + 'min ' + remaining_time.seconds + 's'
        }
      }
    }
  }
  return str
}

const axiosWithAuth = function () {
  return axios.create({
    baseURL: appConfig.API_ROOT,
    headers: { Authorization: `Token ${store.state.authToken}` }
  })
}

const getLocations = async (rent) => {
  locations.value = []
  hasLocData.value = false
  await axiosWithAuth.call(this).get(`rent/${rent.id}/get_Locations`).then(response => {
    locations.value = response.data
  })

  if (locations.value.length > 0) {
    hasLocData.value = true
  }
}

/* WATCHER for History dialog behavior */
watch(hdialog, (current) => {
  if (current === false && !routeUpdateHappened.value) {
    // closing because of click on background
    router.push('/')
  }
})

/* WATCHER for user from Vuex Store
If we have a user, we can authenticate and query rents */
watch(user, () => store.dispatch('GET_ALL_RENTS'))

/* WATCHER for rents, so that we can deactivate the skeleton loader */
watch(rents, () => {
  if (rents.value) {
    rents.value.reverse()
    hasData.value = true
  } else {
    hasData.value = false
  }
})

watchEffect(() => {
  if (hasLocData.value && rentMap.value) {
    const map = new maplibregl.Map({
      container: 'rentMapContainer',
      style: 'https://tileserver.gero.dev/styles/cyclosm-basic-gl-style/style.json',
      center: appConfig.DEFAULT_LOCATION,
      zoom: appConfig.DEFAULT_ZOOM
    })

    map.loadImage('bike_icon.png', (error, image) => {
      if (error) throw error

      map.addImage('bike', image)
    })

    map.loadImage('flag.png', (error, image) => {
      if (error) throw error

      map.addImage('start', image)
    })

    map.loadImage('flag-checkered.png', (error, image) => {
      if (error) throw error

      map.addImage('finish', image)
    })

    map.loadImage('chevron-up.png', (error, image) => {
      if (error) throw error

      map.addImage('arrow', image)
    })

    map.on('load', () => {
      const markers = {
        type: 'FeatureCollection',
        features: locations.value.map(loc => ({
          type: 'Feature',
          properties: {
            locId: locations.value.indexOf(loc)
          },
          geometry: {
            type: 'Point',
            coordinates: [loc.lon, loc.lat]
          }
        }))
      }

      map.addSource('startEndMarkers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [{
            type: 'Feature',
            properties: {
              locId: 0
            },
            geometry: {
              type: 'Point',
              coordinates: [locations.value[0].lon, locations.value[0].lat]
            }
          },
          locations.value.length > 1
            ? {
                type: 'Feature',
                properties: {
                  locId: locations.value.length - 1
                },
                geometry: {
                  type: 'Point',
                  coordinates: [locations.value[locations.value.length - 1].lon, locations.value[locations.value.length - 1].lat]
                }
              }
            : {}]
        }
      })

      markers.features.forEach((marker, i) => {
        const el = document.createElement('div')
        if (i !== 0 && i !== locations.value.length - 1) {
          el.className = 'marker'
          el.innerHTML = '<span><b> ' + (i + 1) + ' </b></span>'
        } else {
          el.className = 'startEndMarker'
          el.innerHTML = '<span></span>'
        }

        new maplibregl.Marker(el)
          .setLngLat(marker.geometry.coordinates)
          .setPopup(new maplibregl.Popup({ offset: 25 })
            .setHTML(new Date(locations.value[marker.properties.locId].reported_at).toLocaleTimeString(['de-DE'])))
          .addTo(map)
      })

      map.addSource('route', {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: locations.value.map(loc => [loc.lon, loc.lat])
          }
        }
      })

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#888',
          'line-width': 8
        }
      })

      map.addLayer({
        id: 'directions',
        type: 'symbol',
        source: 'route',
        paint: {},
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 1,
          'icon-image': 'arrow',
          'icon-rotate': 90,
          'icon-rotation-alignment': 'map',
          'icon-allow-overlap': true,
          'icon-size': 0.1
        }
      })

      map.addLayer({
        id: 'startEndMarkers',
        type: 'symbol',
        source: 'startEndMarkers',
        layout: {
          'icon-image': ['match',
            ['get', 'locId'],
            0,
            'start',
            locations.value.length > 1 ? locations.value.length - 1 : 999,
            'finish',
            'bike'
          ],
          'icon-anchor': 'bottom-left',
          'icon-offset': [-16, 8],
          'icon-allow-overlap': true,
          'icon-size': 0.6
        }
      })

      if (locations.value.length > 1) {
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new maplibregl.LngLatBounds(
          locations.value[0],
          locations.value[0]
        )

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const loc of locations.value) {
          bounds.extend(loc)
        }

        map.fitBounds(bounds, {
          padding: 20
        })
      } else {
        map.setCenter(locations.value[0])
      }
    })
  }
})
</script>

<style>
.history-grid {
  display: grid;
  grid-template-columns: 0px auto 1fr 1fr 1fr;
  width: 100%;
}

.history-grid>* {
  padding: 2pt 5pt;
  white-space: nowrap;
}

.history-grid-row {
  position: relative;
  padding: 0;
}

.history-grid-row .history-grid-row-hover,
.history-grid-row .history-grid-row-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100vw;
  right: -100vw;
}

.history-grid-row .history-grid-row-hover {
  cursor: pointer;
  z-index: 2;
}

.history-grid-value {
  z-index: 1;
}

.history-grid-value:not(:nth-child(5n+2)) {
  text-align: center;
}

/* add padding to the first/last element of each row */
.history-grid>*:nth-child(5n+2) {
  padding-left: 10pt;
}

.history-grid>*:nth-child(5n+5) {
  padding-right: 10pt;
}

/* color every other row in light grey */
.history-grid-row:nth-child(2n) .history-grid-row-bg {
  background-color: lightgrey;
}

/* hover effect */
.history-grid-row-hover:hover + .history-grid-row-bg {
  background-color: #98d42083;
}

/* special header styling */
.history-grid-header {
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 650px) {
  .history-grid {
    grid-auto-flow: row dense;
    grid-template-columns: 0px auto min-content min-content min-content;
  }

  .history-grid-value {
    text-align: center;
  }

  /* Place Start and End headers next to each other */
  .history-grid-header:nth-child(3) {
    text-align: right;
    padding-right: 1ch;
  }

  .history-grid-header:nth-child(4)::before {
    content: "-";
    padding-right: 1ch;
  }

  .history-grid-header:nth-child(4) {
    text-align: left;
    padding-left: 0;
  }

  /* Place Start and End values below each other */
  .history-grid-row,
  .history-grid-value:nth-child(5n+2),
  .history-grid-value:nth-child(5n+5) {
    grid-row-end: span 2;
  }

  .history-grid-value:nth-child(5n+5) {
    grid-column-start: 5;
  }

  .history-grid-value:nth-child(5n+3),
  .history-grid-value:nth-child(5n+4) {
    grid-column-end: span 2;
  }
}

.rentMap {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100000000;
}

/* Fix for Skeleton Loader image */
.v-skeleton-loader__image.v-skeleton-loader__bone {
  height: 100%;
}

.marker {
  width: 0;
  height: 0;
}

.startEndMarker {
  width: 0;
  height: 0;
}

.startEndMarker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #99d420;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg)
}
</style>
