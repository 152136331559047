<template>
  <v-dialog v-model="show" max-width="700">
    <v-card>
      <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
        {{ $t('message.imprint.title') }}
      </v-card-title>

      <v-card-text>
        <p class="mt-4 mb-0" v-html="$tm('message.imprint.text')"></p>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions sticky>
            <v-btn variant="text" @click="dialog = false">{{ $t('message.apperror.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const show = ref(true)

const router = useRouter()

/* WATCHER for dialog behavior */
watch(show, (current) => {
  if (current === false) {
    router.push('/')
  }
})
</script>
