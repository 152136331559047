<template>
  <v-dialog max-width="480" class="d-flex d-sm-flex d-md-none" eager v-model="show">
    <v-card>

      <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
        {{ $t('message.support.title') }}
      </v-card-title>

      <div ref="placeholder"></div>

    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const appConfig = inject('$appConfig')

const show = ref(true)
const type = ref(appConfig.SUPPORT_TYPE)
const url = ref(appConfig.SUPPORT_URL)
const iframeEl = ref(null)
const placeholder = ref(null)

const store = useStore()
const router = useRouter()

/* Get user from Vuex Store */
const user = computed(() => store.state.user)

const listenForHeight = () => {
  const plchldr = placeholder.value
  window.addEventListener('message', function (ev) {
    if (typeof ev.data !== 'object' || ev.data.type !== 'support-height') {
      return
    }

    plchldr.style.height = `${ev.data.value}px`
  }, false)
}

const initIframe = () => {
  iframeEl.value = document.createElement('iframe')
  iframeEl.value.setAttribute('crossorigin', 'anonymous')
  iframeEl.value.setAttribute('frameborder', 0)
  iframeEl.value.setAttribute('style', 'width:100%; height:100%;')
  placeholder.value.appendChild(iframeEl.value)

  const iframeDoc = iframeEl.value.contentWindow.document
  if (type.value === 'zammad') {
    const username = user.value ? user.value.username.replace(/['"]/g, '') : ''

    /* eslint-disable no-useless-escape */
    iframeDoc.open()
      .write(
        `
            <body style="box-sizing: border-box; margin: 0; padding: 1rem;">
              <script src="https://code.jquery.com/jquery-2.1.4.min.js"><\/script>
              <div id="f" style="padding-bottom: 1px;"></div>
              <script id="zammad_form_script" src="${url.value}/assets/form/form.js"><\/script>
              <script>
                const delay = (fn) => () => setTimeout(fn, 0);
                const getContentHeight = () => {
                  const scrollingElement = document.body;
                  const rect = scrollingElement.getBoundingClientRect();
                  const style = getComputedStyle(scrollingElement);
                  return rect.height + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
                };
                const sendHeight = () => window.parent.postMessage({ type: 'support-height', value: getContentHeight() }, '*');
                new MutationObserver(delay(sendHeight)).observe(document.body, { subtree: true, childList: true });
                $('#f').ZammadForm({
                  messageTitle: 'Problem oder Verbesserung melden',
                  messageSubmit: 'Abschicken',
                  messageThankYou: 'Vielen Dank für die Meldung (#%s). Wir kümmern uns umgehend!',
                  showTitle: false,
                  attachmentSupport: true,
                  attributes: [
                    {
                      display: 'Name',
                      name: 'name',
                      tag: 'input',
                      type: 'text',
                      placeholder: 'Your Name',
                      defaultValue: '${username}',
                    },
                    {
                      display: 'Email',
                      name: 'email',
                      tag: 'input',
                      type: 'email',
                      placeholder: 'Your Email',
                    },
                    {
                      display: 'Message',
                      name: 'body',
                      tag: 'textarea',
                      placeholder: 'Your Message...',
                      defaultValue: '',
                      rows: 7,
                    },
                  ]
                });
                sendHeight();
                setTimeout(() => sendHeight(), 1000);
              <\/script>
            </body>
            `
      )
    /* eslint-enable no-useless-escape */
    iframeDoc.close()
  }
}

onMounted(() => nextTick(() => {
  listenForHeight()
  initIframe()
}))

watch(show, (current) => {
  if (current === false) {
    router.push('/')
  }
})

</script>
