<template>
  <div id="attach-sheet">

    <v-row justify="center">
      <!-- BIKEINFO DIALOG -->
      <v-dialog v-if="bikeinfo" v-model="dialog" width="min-content" :scrim="false" style="z-index: 500000;">

        <v-card>

          <BikeInfo :bikeinfo="bikeinfo" id="bikeinfo" :v-model="dialog" />

          <v-card-actions>
            <v-btn variant="text" @click.stop="dialog = false">{{ $t('message.apperror.close') }}</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-row>

    <!-- BOTTOM SHEET -->
    <v-bottom-sheet theme="themelight" v-model="show" :scrim="false" inset eager hide-overlay>

      <!-- 11 + 2 rem to get above FAB -->
      <v-sheet height="13rem" style="border-top-left-radius: 20px; border-top-right-radius: 20px;">

        <v-card-title v-if="!hasData" class="pb-3">
          <v-skeleton-loader type="list-item-avatar" width="100%"></v-skeleton-loader>
        </v-card-title>

        <v-card-title v-if="hasData" class="pb-3">

          <v-card-actions>

            <!-- non-electric vehicle icon -->
            <v-avatar color="primary" size="48" v-if="!electric">
              <v-icon size="32">{{ icon }}</v-icon>
            </v-avatar>

            <!-- electric vehicle combined badge icon -->
            <v-badge v-if="electric" bordered color="yellow">
              <template v-slot:badge>
                <v-icon size="12">{{ iconFlash }}</v-icon>
              </template>

              <v-avatar color="primary" size="48">
                <v-icon size="32">{{ icon }}</v-icon>
              </v-avatar>
            </v-badge>

            <!-- battery icon with range for electric vehicles -->
            <div class="range text-body-2" v-if="hasRange">
              <v-icon size="24">{{ batteryIcon }}</v-icon>
              {{ range }}
            </div>

            <span style="font-weight: normal;" class="ms-4">{{ description }}</span>

            <v-spacer></v-spacer>

            <!-- BUTTON for BikeInfo dialog -->

            <v-btn v-if="user && bikeinfo && bikeinfo['info']" @click="dialog = true;" variant="elevated">
              {{ $t('message.detail.info') }}
            </v-btn>

            <!-- <v-skeleton-loader v-if="user && !bikeinfo_html" type="button" ></v-skeleton-loader> -->

            <span v-if="user && bikeinfo && !bikeinfo['info']">{{ $t('message.detail.info_error') }}</span>

          </v-card-actions>

        </v-card-title>

        <!-- ping OK info -->
        <v-card-title v-if="hasData && !isAfk" class="pb-3">
          <v-avatar size="16">
            <v-icon size="16" color="green" center>{{ reportIcon }}</v-icon>
          </v-avatar>
          <span style="font-weight: normal;" class="ms-4">{{ $t('message.detail.ping') }} {{ lastReported }}</span>
        </v-card-title>

        <!-- ping AFK info -->
        <v-card-title v-else-if="hasData && isAfk" class="pb-3 text-red-lighten-2">
          <v-avatar size="16">
            <v-icon size="16" color="red" center>{{ reportIcon }}</v-icon>
          </v-avatar>
          <span style="font-weight: normal;" class="ms-4">{{ $t('message.detail.ping') }} {{ lastReported }}</span>
        </v-card-title>

      </v-sheet>
    </v-bottom-sheet><!-- #attach-sheet -->
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, onUnmounted, ref, watch, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import * as convert from 'convert-units'
import BikeInfo from './BikeInfo.vue'
import {
  mdiBicycle,
  mdiBicycleBasket,
  mdiScooter,
  mdiCar,
  mdiMoped,
  mdiBus,
  mdiFlash,
  mdiBatteryHigh,
  mdiBatteryMedium,
  mdiBatteryLow,
  mdiBatteryOutline,
  mdiBatteryUnknown,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline
} from '@mdi/js'

const show = ref(true)
const dialog = ref(false)
const routeUpdateHappened = ref(false)
const hasData = ref(false)
const iconFlash = ref(mdiFlash)
const bike = ref(undefined)
const vehicle_type = ref(undefined)

const props = defineProps({
  id: String,
  modelValue: String
})

const { t } = useI18n()
const store = useStore()
const route = useRoute()

/* Get user from Vuex store */
const user = computed(() => store.state.user)

/* Get bike info from Vuex store */
const bikeinfo = computed(() => store.state.bike_info)

/* Compute icon depending onv vehicle type */
const icon = computed(() => {
  if (typeof vehicle_type.value === 'undefined') return mdiBicycle
  if (vehicle_type.value.form_factor === 'bike') {
    if (vehicle_type.value.name.toLowerCase().includes('cargo')) return mdiBicycleBasket
    else return mdiBicycle
  }
  if (vehicle_type.value.form_factor === 'scooter') return mdiScooter
  if (vehicle_type.value.form_factor === 'car') return mdiCar
  if (vehicle_type.value.form_factor === 'moped') return mdiMoped
  if (vehicle_type.value.form_factor === 'other') return mdiBus
  return mdiBicycle
})

/* Compute Battery Icon */
const batteryIcon = computed(() => {
  if (typeof vehicle_type.value === 'undefined') return mdiBatteryUnknown
  if (typeof vehicle_type.value.max_range_meters === 'undefined' || vehicle_type.value.max_range_meters === 0) return mdiBatteryUnknown
  const currentCharge = bike.value.current_range_meters / vehicle_type.value.max_range_meters
  if (currentCharge >= 0.66) return mdiBatteryHigh
  if (currentCharge >= 0.33) return mdiBatteryMedium
  if (currentCharge >= 0.1) return mdiBatteryLow
  return mdiBatteryOutline
})

/* Get hasRange bool */
const hasRange = computed(() => {
  if (typeof bike.value.current_range_meters === 'undefined') return false
  if (typeof vehicle_type.value === 'undefined') return false
  if (typeof vehicle_type.value.max_range_meters === 'undefined' || vehicle_type.value.max_range_meters === 0) return false
  return true
})

/* Get range */
const range = computed(() => {
  if (!hasRange.value) return '?'

  if (bike.value.current_range_meters <= 0) return t('message.detail.no-range')

  const range = convert(bike.value.current_range_meters).from('m').toBest()
  return t('message.detail.range', { value: range.val, unit: range.unit })
})

/* Get description */
const description = computed(() => {
  if (typeof vehicle_type.value === 'undefined') return ''
  return vehicle_type.value.name
})

/* Get electric bool */
const electric = computed(() => {
  if (typeof vehicle_type.value === 'undefined') return false
  if (vehicle_type.value.propulsion_type === 'electric' ||
    vehicle_type.value.propulsion_type === 'electric_assist') {
    return true
  }
  return false
})

/* Get last reported date */
const lastReported = computed(() => {
  const date = new Date(bike.value.last_reported * 1000).toLocaleTimeString()

  return date
})

/* Get isAfk bool */
const isAfk = computed(() => {
  if (Math.floor(Date.now() / 1000) - bike.value.last_reported >= 3600) return true
  return false
})

/* Get report icon */
const reportIcon = computed(() => {
  if (Math.floor(Date.now() / 1000) - bike.value.last_reported >= 3600) return mdiAlertCircleOutline
  return mdiCheckCircleOutline
})

/* Get GBFS from Vuex Store */
const gbfs = computed(() => store.state.gbfs)

/* Load GBFS Vehicle Details */
const loadDetailFromGbfs = () => {
  hasData.value = false

  const bik = store.getters.getGBFSBikeWithDetails(props.id)
  if (bik) {
    bike.value = bik.bike
    vehicle_type.value = bik.vehicle_type
    store.dispatch('GET_BIKE_INFO', bik.bike.bike_id)
      .then(() => { hasData.value = true })
  } else {
    hasData.value = false
  }
}

onMounted(() => {
  if (gbfs.value) {
    loadDetailFromGbfs()
  }
})

onUnmounted(() => {
  store.commit('CLEAR_BIKE_INFO')
})

/* WATCHER for BikeInfo dialog behavior */
watch(show, (current) => {
  if (!current && routeUpdateHappened.value) {
    // from detail view of one vehicle to another
    // (leave router push in GbfsViews responsibility)
    routeUpdateHappened.value = false
    nextTick(() => {
      show.value = true
    })
  }
})

/* WATCHER for route updates */
watch(route, (to) => {
  // displaying another vehicle
  if (to.name === 'vehicle') {
    routeUpdateHappened.value = true
    loadDetailFromGbfs()
  } else {
    routeUpdateHappened.value = false
  }
}, { immediate: true })

watch(gbfs, () => loadDetailFromGbfs())

</script>

<style>
.v-avatar .v-icon {
  border-radius: 0;
}

.range {
  flex: 1 1 auto;
  font-weight: normal;
  text-align: right;
}
</style>
