<template>
  <v-app>
    <v-app-bar color="background" theme="themedark" flat>
      <v-toolbar-title class="d-none">{{ name }}</v-toolbar-title>

      <img :src="logoUrl" height="48" :title="name" style="padding-left:10px;" class="sm-flex" />

      <div class="flex-grow-1"></div>

      <v-toolbar-items>

        <v-btn variant="text" :to="{ name: 'imprint' }" color="grey">
          <span class="d-none d-sm-flex">{{ $t('message.imprint.title') }}</span>
          <v-icon end>{{ mdi.balance }}</v-icon>
        </v-btn>

        <v-divider vertical inset :thickness="2"></v-divider>

        <About />

        <v-btn variant="text" v-if="!user" :to="{ name: 'login' }" color="success">
          <span class="d-none d-sm-flex">{{ $t('message.app.login') }}</span>
          <v-icon end>{{ mdi.login }}</v-icon>
        </v-btn>

        <!-- LOGOUT MENU -->
        <v-menu v-if="user" v-model="profileMenu" location="bottom" z-index="2500">

          <!-- USER BUTTON with activator for Logout Menu -->
          <template v-slot:activator="{ props }">
            <v-btn variant="text" @click="profileMenu = true" v-bind="props">
              <v-icon>{{ mdi.dotsVertical }}</v-icon>

              <v-avatar color="#afca0b" class="d-flex d-sm-none" size="40">
                <span class="d-flex d-sm-none">{{ Array.from(user.username)[0] }}</span>
              </v-avatar>

              <span class="d-none d-sm-flex d-lg-flex">{{ user.username }}</span>

            </v-btn>
          </template>

          <v-list theme="themelight">

            <v-list-item :to="{ name: 'history' }">
              <v-list-item-title>{{ $t('message.app.history') }}</v-list-item-title>
            </v-list-item>

            <v-list-item :href="tosUrl" target="_blank">
              <v-list-item-title>{{ $t('message.app.gtc') }}</v-list-item-title>
            </v-list-item>

            <v-list-item @click="logout()">
              <v-list-item-title>{{ $t('message.app.logout') }}</v-list-item-title>
            </v-list-item>

          </v-list>

        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <ToS v-model="showToSPopup" :user="user" :url="tosUrl" :version="tosVersion" />
      <!-- ALERT if user account cant rent bike -->
      <v-alert multi-line v-if="user && !user.can_rent_bike" border="bottom" border-color type="warning" class="mb-0"
        density="compact">
        <p class="text-wrap">{{ $t('message.app.unvalidated-message') }}</p>
      </v-alert>

      <gbfs-view />
      <router-view></router-view>

      <!-- RENT/LOGIN/SUPPORT BUTTON -->
      <div class="rent-buttonarea">

        <!-- SUPPORT BUTTON-->
        <v-btn icon size="x-small" v-if="hasSupport" :to="{ name: 'support' }" class="support-button">
          <v-icon>{{ mdi.helpCircleOutline }}</v-icon>
        </v-btn>

        <!-- LOGIN BUTTON -->
        <v-btn rounded theme="themedark" size="x-large" color="success" class="login-button" v-if="!user"
          :to="{ name: 'login' }">
          <p style="color:white">
            <v-icon>{{ mdi.login }}</v-icon>&nbsp;<span>{{ $t('message.app.login') }}</span>
          </p>
        </v-btn>

        <RentButton v-if="user" theme="themedark" class="rent-button" />

      </div>

      <AppError />

    </v-main>
  </v-app>
</template>

<script setup>
import { computed, inject, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { mdiLogin, mdiDotsVertical, mdiHelpCircleOutline, mdiScaleBalance } from '@mdi/js'
import About from './components/About'
import GbfsView from './components/GbfsView'
import RentButton from './components/RentButton'
import AppError from './components/AppError'
import ToS from './components/ToS'

const appConfig = inject('$appConfig')

const blank = (v) => !(typeof v !== 'undefined' && v !== '')

const profileMenu = ref(false)
const showToSPopup = ref(false)
const name = ref(appConfig.name)
const hasSupport = ref(!blank(appConfig.SUPPORT_URL))
const mdi = reactive({
  login: mdiLogin,
  dotsVertical: mdiDotsVertical,
  helpCircleOutline: mdiHelpCircleOutline,
  balance: mdiScaleBalance
})
const tosUrl = ref('')
const tosVersion = ref('')

const store = useStore()

/* Get logo */
const logoUrl = computed(() => {
  const env = appConfig.ENV
  return require('@/assets/logo' + (env !== 'production' ? '.' + env : '') + '.png')
})

/* Get user from Vuex Store */
const user = computed(() => store.state.user)

/* Dispatch Logout event to Vuex Store */
const logout = () => {
  store.dispatch('LOGOUT')
}

/* WATCHER for user to get rents */
watch(user, async () => {
  await store.dispatch('GET_LATEST_TOS').then((tos) => {
    tosUrl.value = appConfig.SYSTEM_URL + tos.file
    tosVersion.value = tos.version

    if (user.value.last_accepted_tos !== tos.version) {
      showToSPopup.value = true
    } else {
      showToSPopup.value = false
    }
  })

  store.dispatch('UPDATE_RENTS')
})

</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
}

.support-button,
.rent-button,
.login-button {
  position: fixed;
  bottom: 2rem;
  text-align: center;
}

/* bad hack, goes away when we restructure the navigation */
#attach-sheet+.rent-buttonarea .rent-button,
#attach-sheet+.rent-buttonarea .login-button {
  z-index: 10000;
}

.rent-button,
.login-button {
  left: 50%;
  transform: translateX(-50%);
}

.support-button {
  left: 1rem;
}
</style>
