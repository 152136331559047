<template>
  <div id="mapContainer" class="basemap" ref="gbfsLayer"></div>
</template>

<script setup>
import { computed, onMounted, ref, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import maplibregl from 'maplibre-gl'
import { useStore } from 'vuex'
import * as THREE from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

const gbfsLayer = ref(null)

const store = useStore()

const router = useRouter()

const { t } = useI18n()

const appConfig = inject('$appConfig')

/* Load active rents of the user from Vuex Store */
const rents = computed(() => store.state.rents)

/* Get user from Vuex Store */
const user = computed(() => store.state.user)

const eastereggs = [
  [6.08183, 50.77569],
  [6.07953, 50.78333],
  [6.06671, 50.77831],
  [6.06075, 50.77848],
  [6.07680, 50.78616],
  [6.01839, 50.77347]
]

onMounted(async () => {
  let updating

  /* MAP object */
  const map = new maplibregl.Map({
    container: 'mapContainer',
    style: 'https://tileserver.gero.dev/styles/cyclosm-basic-gl-style/style.json',
    center: appConfig.DEFAULT_LOCATION,
    zoom: appConfig.DEFAULT_ZOOM,
    antialias: true
  })

  /* MAP NAV-CONTROL */
  map.addControl(new maplibregl.NavigationControl({
    visualizePitch: true
  }), 'top-left')

  /* MAP GEO-CONTROL */
  map.addControl(
    new maplibregl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    }), 'top-left'
  )

  /* NAV SCALE-CONTROL */
  map.addControl(new maplibregl.ScaleControl(), 'bottom-left')

  map.on('load', () => {
    // Insert the layer beneath POI label layer
    const layers = map.getStyle().layers
    const labelLayerId = layers.find(
      (layer) => layer.type === 'symbol' && layer.layout['text-field'] && layer.id.includes('label') && layer.id.includes('place')
    ).id

    /* MAP LAYER for 3D buildings */
    map.addLayer(
      {
        id: '3d-buildings',
        source: 'openmaptiles',
        'source-layer': 'building',
        type: 'fill-extrusion',
        filter: ['!', ['has', 'hide_3d']],
        minzoom: 16,
        paint: {
          'fill-extrusion-color': 'rgba(222, 211, 190, 1)',

          // use an 'interpolate' expression to add a smooth transition effect to the
          // buildings as the user zooms in
          'fill-extrusion-height': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'render_height']
          ],
          'fill-extrusion-base': [
            'interpolate',
            ['linear'],
            ['zoom'],
            15,
            0,
            15.05,
            ['get', 'render_min_height']
          ],
          'fill-extrusion-opacity': 0.6
        }
      },
      labelLayerId
    )

    map.addSource('eastereggs', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: eastereggs.map(egg => ({
          type: 'Feature',
          properties: {
            id: eastereggs.indexOf(egg)
          },
          geometry: {
            type: 'Point',
            coordinates: [egg[0], egg[1]]
          }
        }))
      }
    })

    /* AACHEN CATHEDRAL 3D Model definitions */
    // parameters to ensure the model is georeferenced correctly on the map
    const modelOrigin = [6.0839561, 50.774745]
    const modelAltitude = 0
    const modelRotate = [0, 0, -1.56]

    const modelAsMercatorCoordinate = maplibregl.MercatorCoordinate.fromLngLat(
      modelOrigin,
      modelAltitude
    )

    // transformation parameters to position, rotate and scale the 3D model onto the map
    const modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: modelRotate[0],
      rotateY: modelRotate[1],
      rotateZ: modelRotate[2],
      /* Since our 3D model is in real world meters, a scale transform needs to be
      * applied since the CustomLayerInterface expects units in MercatorCoordinates.
      */
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 0.38
    }

    // configuration of the custom layer for a 3D model per the CustomLayerInterface
    const customLayer = {
      id: '3d-aachen-cathedral',
      type: 'custom',
      renderingMode: '3d',
      onAdd: function (map, gl) {
        this.camera = new THREE.Camera()
        this.scene = new THREE.Scene()

        // create two three.js lights to illuminate the model
        const directionalLight = new THREE.DirectionalLight(0xffffff)
        directionalLight.position.set(0, -70, 100).normalize()
        this.scene.add(directionalLight)

        const directionalLight2 = new THREE.DirectionalLight(0xffffff)
        directionalLight2.position.set(0, 70, 100).normalize()
        this.scene.add(directionalLight2)

        const material = new THREE.MeshStandardMaterial({ color: '#ded3be', transparent: true, opacity: 0.6 })

        // use the three.js STL loader to add the 3D model to the three.js scene
        const loader = new STLLoader()
        loader.load(
          '/Dom_Aachen_2021.stl',
          function (geometry) {
            geometry.computeBoundingBox()
            const ztranslate = geometry.boundingBox.max.z
            geometry.center()
            geometry.translate(0, 0, ztranslate / 2)
            const mesh = new THREE.Mesh(geometry, material)
            this.scene.add(mesh)
          }.bind(this),
          (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
          },
          (error) => {
            console.log(error)
          }
        )
        this.map = map

        // use the MapLibre GL JS map canvas for three.js
        this.renderer = new THREE.WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true
        })

        this.renderer.autoClear = false
      },
      render: function (gl, matrix) {
        const rotationX = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(1, 0, 0),
          modelTransform.rotateX
        )
        const rotationY = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 1, 0),
          modelTransform.rotateY
        )
        const rotationZ = new THREE.Matrix4().makeRotationAxis(
          new THREE.Vector3(0, 0, 1),
          modelTransform.rotateZ
        )

        const m = new THREE.Matrix4().fromArray(matrix)
        const l = new THREE.Matrix4()
          .makeTranslation(
            modelTransform.translateX,
            modelTransform.translateY,
            modelTransform.translateZ
          )
          .scale(
            new THREE.Vector3(
              modelTransform.scale,
              -modelTransform.scale,
              modelTransform.scale
            )
          )
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ)

        this.camera.projectionMatrix = m.multiply(l)
        this.renderer.resetState()
        this.renderer.render(this.scene, this.camera)
        this.map.triggerRepaint()
      }
    }

    map.addLayer(customLayer)

    map.setLayerZoomRange('3d-aachen-cathedral', 16, 24)

    /* MAP BIKE ICONS */
    map.loadImage('/bike_icon.png', (error, image) => {
      if (error) throw error

      map.addImage('bike', image)
    })

    map.loadImage('/logo.png', (error, image) => {
      if (error) throw error

      map.addImage('egg', image)
    })

    map.addLayer({
      id: 'eastereggs',
      type: 'symbol',
      minzoom: 22,
      source: 'eastereggs',
      layout: {
        'icon-image': 'egg',
        'icon-allow-overlap': true,
        'icon-size': 0.15
      }
    })
    map.resize()
  })

  try {
    // Fetch GBFS stuff
    const gbfsResponse = await fetch(appConfig.GBFS_URL)
    const gbfs = await gbfsResponse.json()
    const languages = Object.keys(gbfs.data)
    let language
    if (languages.length === 0) {
      throw new Error('GBFS has no languages defined')
    } else {
      language = languages[0]
    }

    // Get GBFS Feeds
    const feeds = gbfs.data[language].feeds
    const stationInformationJson = feeds.find((el) => el.name === 'station_information')
    const stationStatusJson = feeds.find((el) => el.name === 'station_status')
    const freeBikeStatusJson = feeds.find((el) => el.name === 'free_bike_status')
    let vehicleTypes = feeds.find((el) => el.name === 'vehicle_types')

    // Fetch and parse GBFS Feeds
    const stationInformationResponse = await fetch(stationInformationJson.url)
    const stations = await stationInformationResponse.json()
    const stationStatusResponse = await fetch(stationStatusJson.url)
    const stationStatus = await stationStatusResponse.json()
    const freeBikeStatusResponse = await fetch(freeBikeStatusJson.url)
    const freeBikeStatus = await freeBikeStatusResponse.json()
    if (typeof vehicleTypes !== 'undefined') {
      const vehicleTypesResponse = await fetch(vehicleTypes.url)
      vehicleTypes = await vehicleTypesResponse.json()
    }

    // Set GBFS Data in Vuex Store
    store.commit('SET_GBFS', { stations, stationStatus, freeBikeStatus, vehicleTypes })

    // Draw transparent uncertainty circles around bikes from GBFS Data
    const getCircles = (bikes, radiusMeters) => {
      const points = 64
      let ret = []
      const res = []

      bikes.forEach(bike => {
        ret = []

        const distanceX = radiusMeters / (111320 * Math.cos(bike.lat * Math.PI / 180))
        const distanceY = radiusMeters / 110574

        let theta, x, y

        for (let i = 0; i < points; i++) {
          theta = (i / points) * (2 * Math.PI)
          x = distanceX * Math.cos(theta)
          y = distanceY * Math.sin(theta)

          ret.push([bike.lon + x, bike.lat + y])
        }
        ret.push(ret[0])

        res.push({
          type: 'Feature',
          properties: {
            bikeId: bike.bike_id
          },
          geometry: {
            type: 'Polygon',
            coordinates: [ret]
          }
        })
      })

      return res
    }

    // Add circle Polygons as source to Map
    map.addSource('bikeradii', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: getCircles(freeBikeStatus.data.bikes, 50) // meters
      }
    }
    )

    // Add bike locations as source to map
    map.addSource('bikes', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: freeBikeStatus.data.bikes.map(bike => ({
          type: 'Feature',
          properties: {
            bikeId: bike.bike_id
          },
          geometry: {
            type: 'Point',
            coordinates: [bike.lon, bike.lat]
          }
        }))
      }
    })

    // Add circle layer to map
    map.addLayer({
      id: 'bikeradii',
      type: 'fill',
      source: 'bikeradii',
      paint: {
        'fill-color': 'blue',
        'fill-opacity': 0.3
      }
    })

    // Add bike location layer to map
    map.addLayer({
      id: 'bikes',
      type: 'symbol',
      source: 'bikes',
      layout: {
        'icon-image': 'bike',
        'icon-allow-overlap': true,
        'icon-size': 0.6
      }
    })

    // Click event handler for bike icons
    map.on('click', 'bikes', (e) => {
      e.preventDefault()
      router.push('/v/' + e.features[0].properties.bikeId)
    })

    map.on('click', (e) => {
      if (!e.defaultPrevented) {
        router.push('/')
      }
    })

    // Mouse enter event handler for bike icons
    map.on('mouseenter', 'bikes', () => {
      map.getCanvas().style.cursor = 'pointer'
    })

    // Mouse leave event handler for bike icons
    map.on('mouseleave', 'bikes', () => {
      map.getCanvas().style.cursor = ''
    })

    // Update GBFS Stuff
    const update = async () => {
      if (!updating) {
        updating = true

        try {
          const stationInformationResponse = await fetch(stationInformationJson.url)
          const stations = await stationInformationResponse.json()
          const stationStatusResponse = await fetch(stationStatusJson.url)
          const stationStatus = await stationStatusResponse.json()
          const freeBikeStatusResponse = await fetch(freeBikeStatusJson.url)
          const freeBikeStatus = await freeBikeStatusResponse.json()
          if (typeof feeds.vehicleTypes !== 'undefined') {
            const vehicleTypesResponse = await fetch(vehicleTypes.url)
            vehicleTypes = await vehicleTypesResponse.json()
          }

          store.commit('SET_GBFS', { stations, stationStatus, freeBikeStatus, vehicleTypes })

          map.getSource('bikeradii').setData({
            type: 'FeatureCollection',
            features: getCircles(freeBikeStatus.data.bikes, 50)
          })

          map.getSource('bikes').setData({
            type: 'FeatureCollection',
            features: freeBikeStatus.data.bikes.map(bike => ({
              type: 'Feature',
              properties: {
                bikeId: bike.bike_id
              },
              geometry: {
                type: 'Point',
                coordinates: [bike.lon, bike.lat]
              }
            }))

          })
        } catch (err) {
          updating = false
          console.warn(err)
          store.commit('SET_APPERROR', t('message.gbfsview.error'))
        }
        updating = false
      }
    }

    /* WATCHER for rent updates */
    watch(rents, () => {
      if (gbfsLayer.value) {
        update()
      }
    })

    /* Update GBFS */
    setInterval(() => update(), 60 * 1000)

    /* Update rents in Vuex Store if user is logged in */
    if (user.value) {
      setInterval(() => store.dispatch('UPDATE_RENTS'), 10000)
    }
  } catch (err) {
    console.warn(err)
    store.commit('SET_APPERROR', t('message.gbfsview.error'))
  }
})

</script>

<style lang="scss" scoped>
.basemap {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
