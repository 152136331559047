import { createVuetify } from 'vuetify/lib/framework.mjs'
import { VSkeletonLoader, VBottomSheet } from 'vuetify/lib/components'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

const themedark = {
  dark: true,
  colors: {
    primary: '#99d420',
    success: '#99d420',
    lighterred: '#E57373',
    background: '#272727'
  }
}

const themelight = {
  colors: {
    success: '#99d420',
    primary: '#99d420'
  }
}

const vuetify = createVuetify({
  components: {
    VSkeletonLoader,
    VBottomSheet
  },
  icons: {
    iconfont: 'mdiSvg',
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    themes: {
      themelight,
      themedark
    }
  }
})

export default vuetify
