<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const router = useRouter()

const store = useStore()

const pullToken = () => {
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  if (token) {
    const a = store.dispatch('AUTHENTICATE', token)
    const redir = sessionStorage.getItem('redirect')
    if (redir) {
      sessionStorage.removeItem('redirect')
      a.then(() => {
        router.replace({ path: redir })
      })
      return
    }
  }

  router.replace({ path: '/' })
}

pullToken()
</script>
