<template>
  <v-dialog v-model="show" max-width="400">
    <v-card :style="[rents.length === 0 ? { 'overflow-y': 'clip' } : { 'overflow-y': 'scroll' }]">

      <v-form v-model="valid" ref="rentBikeForm" @submit.prevent="startRent">
        <v-container class="pb-0">

          <v-row v-if="rentError">

            <v-col col="12" md="12" class="py-0">
              <v-alert density="compact" variant="outlined" type="error" class="my-0" style="position:relative">
                {{ rentError }}
              </v-alert>
            </v-col>

          </v-row>

          <v-row class="pt-5">

            <v-col cols="6" md="6" class="py-0 pr-0">
              <v-text-field type="text" :label="$t('message.rent.bikenumber')" v-model="bikenumber" variant="outlined"
                required autofocus :rules="bikenumberrules" inputmode="numeric" pattern="[0-9]*"></v-text-field>
            </v-col>

            <v-col cols="6" md="6" class="py-0 text-right">
              <v-btn class="mt-2" theme="themelight" color="success" v-bind:disabled="!checked" :loading="loading"
                @click="startRent(); checked = false;">
                <p style="color:white">{{ $t('message.rent.rent') }}</p>
              </v-btn>
              <br>
            </v-col>

          </v-row>
          <input type="checkbox" id="checkbox" v-model="checked" />
          <label for="checkbox" v-html="$t('message.tos.statement')"></label>
        </v-container>
      </v-form>

      <v-divider class="mx-4" v-if="rents"></v-divider>

      <!-- ACTIVE RENTS LIST -->
      <v-list-item v-for="rent in rents" :key="rent.id">

        <v-list-item-title>{{ $t('message.rent.bikenumber') }}: {{ rent.bike.bike_number }}</v-list-item-title>
        <v-list-item-subtitle v-if="rent.bike.lock_type">
          <RentLock :rent="rent" />
        </v-list-item-subtitle>
        <v-list-item-subtitle>{{ $t('message.rent.renting-for') }} <ticking-time
            :datetime="rent.rent_start" /></v-list-item-subtitle>
        <v-btn theme="themelight" color="success" @click="endRent(rent.id)"
          v-bind:loading="loadingRents.includes(rent.id)">
          <p style="color:white">{{ $t('message.rent.finish-rent') }}</p>
        </v-btn>

      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import { mdiLock, mdiLockOpenVariant } from '@mdi/js'
import TickingTime from './TickingTime.vue'
import RentLock from './RentLock.vue'

const show = ref(true)
const valid = ref(false)
const loading = ref(false)
const bikenumber = ref('')
const rentError = ref('')
const loadingRents = ref([])
const rentBikeForm = ref(null)
// const mdi = reactive({
//   lock: mdiLock,
//   lockOpenVariant: mdiLockOpenVariant
// })
const checked = ref(false)

/* Set input pattern for form text field */
const bikenumberrules = ref([
  value => {
    const pattern = /^\d+$/
    if (value === '' || typeof value === 'undefined') return true
    return pattern.test(value) || 'Please only numbers'
  }
])

const props = defineProps(['bikeId'])

const store = useStore()

const router = useRouter()

const rents = computed(() => store.state.rents)

/* WATCHER for dialog behavior */
watch(show, (current) => {
  if (current === false) {
    router.push('/')
    if (rentBikeForm.value) {
      rentBikeForm.value.reset()
    }
    rentError.value = ''
  }
}
)

/* Dispatch rent start to Vuex store */
const startRent = () => {
  loading.value = true
  rentError.value = ''
  store.dispatch('START_RENT', bikenumber.value)
    .then(() => rentBikeForm.value.reset(),
      (err) => (rentError.value = err))
    .finally(() => (loading.value = false))
}

/* Dispatch rent end to Vuex store */
const endRent = (rentId) => {
  rentError.value = ''
  loadingRents.value.push(rentId)
  store.dispatch('END_RENT', rentId)
    .catch(err => {
      rentError.value = err
      const index = loadingRents.value.indexOf(rentId)
      if (index >= 0) {
        loadingRents.value.splice(index, 1)
      }
    })
}

onMounted(() => {
  bikenumber.value = props.bikeId
})
</script>

<style>
.rent-unlock-key {
  font-family: monospace;
  font-size: 20px;
  font-weight: bold;
}
</style>
