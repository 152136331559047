<template>
  <time :datetime="datetime">{{ tick }}</time>
</template>

<script setup>
import { computed, defineProps, onBeforeUnmount, onMounted, ref } from 'vue'

/* Prepend leading zeroes for one-digit numbers */
const leadingZero = (num) => `0${num}`.slice(-2)

const now = ref(Date.now())
const interval = ref(null)

const props = defineProps(['datetime'])

/* Compute elapsed rent time for each tick */
const tick = computed(() => {
  if (!now.value) return

  const startDate = new Date(props.datetime)
  const diff = Math.floor((now.value - startDate.getTime()) / 1000)
  const hours = Math.max(0, Math.floor(diff / 3600))
  const minutes = Math.max(0, Math.floor((diff - hours * 3600) / 60))
  const seconds = Math.max(0, diff - (hours * 3600 + minutes * 60))
  return `${leadingZero(hours)}:${leadingZero(minutes)}:${leadingZero(seconds)}`
})

/* On mount, set ticking interval to once per second */
onMounted(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
  interval.value = setInterval(() => {
    now.value = Date.now()
  }, 1000)
})

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
})

</script>
