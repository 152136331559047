<template>
  <div>

    <!-- COMBINATION LOCK -->
    <template v-if="rent.bike.lock_type === 'CL'">
      {{ $t('message.rent.unlockcode') }}:
      <span class="rent-unlock-key">{{ unlock_key }}</span>
      <v-progress-circular indeterminate size="16" width="2" v-if="loading"></v-progress-circular>
    </template>

    <!-- ELECTRONIC LOCK -->
    <template v-else-if="rent.bike.lock_type === 'EL'">
      <v-btn color="accent" rounded variant="outlined" elevation="0" @click="unlock()" v-bind:loading="loading">
        <v-icon>{{ mdi.lockOpenVariant }}</v-icon>&nbsp;
        <span>{{ $t('message.rent.unlock') }}</span>
      </v-btn>
    </template>

    <!-- DEFAULT -->
    <template v-else>
      <small>{{ $t('message.lock.not-supported') }}</small>
    </template>

  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { mdiLock, mdiLockOpenVariant } from '@mdi/js'

const loading = ref(false)
const error = ref('')
const mdi = reactive({
  lock: mdiLock,
  lockOpenVariant: mdiLockOpenVariant
})

const props = defineProps(['rent'])

const store = useStore()

/* Get Lock unlock key */
const unlock_key = computed(() => {
  if (props.rent.bike.lock_type !== 'CL') return ''
  const lock = store.state.lock[props.rent.id]
  if (!lock) return ''
  return lock.unlock_key
})

/* Dispatch unlock event to Vuex Store */
const unlock = () => {
  loading.value = true
  store.dispatch('RENT_UNLOCK', props.rent.id)
    .then(() => { /* nothing */ },
      (err) => (error.value = err))
    .finally(() => (loading.value = false))
}

/* Auto "unlock" combination locks on mounted */
onMounted(() => {
  if (props.rent.bike.lock_type === 'CL') {
    unlock()
  }
})

</script>

<style>
.rent-unlock-key {
  font-family: monospace;
  font-size: 20px;
  font-weight: bold;
}
</style>
