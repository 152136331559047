import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'

import Login from '../components/Login'
import LoginReturn from '../components/LoginReturn'
import Rent from '../components/Rent'
import History from '../components/History'
import Imprint from '../components/Imprint'
import Support from '../components/Support'
import VehicleDetail from '../components/VehicleDetail'
import StationDetail from '../components/StationDetail'

export function makeRouter (noop) {
  const router = createRouter({
    history: createWebHistory(),
    routes: [
      { path: '/', component: noop },
      { path: '/login', name: 'login', component: Login },
      { path: '/login/return', component: LoginReturn },
      {
        path: '/rent',
        name: 'rent',
        component: Rent,
        meta: { requiresAuth: true },
        props: (route) => {
          if (route.query && route.query.id) {
            return { bikeId: route.query.id }
          }
          return {}
        }
      },
      {
        path: '/history',
        name: 'history',
        component: History
      },
      {
        path: '/imprint',
        name: 'imprint',
        component: Imprint
      },
      {
        path: '/b/:id',
        redirect: to => {
          const { params } = to
          return { path: '/rent', query: { id: params.id } }
        }
      },
      { path: '/v/:id', name: 'vehicle', component: VehicleDetail, props: true },
      { path: '/s/:id', name: 'station', component: StationDetail, props: true },
      { path: '/support', name: 'support', component: Support },
      { path: '/:pathMatch(.*)*', redirect: '/' }
    ]
  })

  let initialAuthTriggered = false

  router.beforeEach((to, from, next) => {
    if (!to.meta.requiresAuth) {
      next()
      return
    }

    initialAuthTriggered = true
    store.dispatch('IS_AUTHENTICATED')
      .then(() => {
        next()
      })
      .catch(() => {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        })
      })
  })

  router.isReady().then(() => {
    if (!initialAuthTriggered) {
      store.dispatch('IS_AUTHENTICATED').catch(() => {})
      initialAuthTriggered = true
    }
  })

  return router
}
