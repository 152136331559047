<template>
  <v-dialog v-model="show" max-width="400">
    <v-card>
      <v-card-title class="text-h5 bg-grey-lighten-2" primary-title>
        {{ $t('message.login.login-with') }}
      </v-card-title>

      <v-card-text>
        <p class="mt-4 mb-0" v-html="$t('message.login.login-notice')"></p>
      </v-card-text>

      <v-card-actions>
        <v-list width="100%">

          <div v-if="loading" class="loading">
            <v-skeleton-loader boilerplate tile type="list-item@3"></v-skeleton-loader>
          </div>

          <v-list-item v-for="provider in providers" :key="provider.id">
            <v-btn variant="outlined" :color="provider.color" block @click="authenticate(provider)">
              {{ provider.name }}
              <!-- <v-icon v-if="provider.icon" right dark>{{ provider.icon }}</v-icon> -->
            </v-btn>
          </v-list-item>

        </v-list>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, inject, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const show = ref(true)
const loading = ref(false)
const providers = ref([])

const appConfig = inject('$appConfig')

const store = useStore()

const router = useRouter()

const { t } = useI18n()

/* Auth */
const authenticate = (provider) => {
  const params = new URLSearchParams(location.search)
  if (params.get('redirect')) {
    sessionStorage.setItem('redirect', params.get('redirect'))
  }
  location.href = provider.auth_url
}

/* Get login providers from backend */
const fetchProviders = () => {
  loading.value = true
  const url = appConfig.API_ROOT + '/config/loginproviders'
  fetch(url)
    .then(r => r.json())
    .then(data => {
      providers.value = data
      loading.value = false
    })
    .catch(() => {
      store.commit('SET_APPERROR', t('message.login.configerror'))
    })
}

/* WATCHER for dialog behavior */
watch(show, (current) => {
  if (current === false) {
    router.push('/')
  }
})

fetchProviders()
</script>
